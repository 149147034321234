export function classNames() {
    const classes = [];

    for (let i = 0; i < arguments.length; i++) {
        /* eslint-disable-next-line */
        const arg = arguments[i];

        if (!arg) {
            continue;
        }

        const argType = typeof arg;

        if (argType === 'string' || argType === 'number') {
            classes.push(arg);
        } else if (Array.isArray(arg) && arg.length) {
            /* eslint-disable-next-line */
            const inner = classNames.apply(null, arg);

            if (inner) {
                classes.push(inner);
            }
        } else if (argType === 'object') {
            /* eslint-disable */
            for (const key in arg) {
                if (arg.hasOwnProperty(key) && arg[key]) {
                    classes.push(key);
                }
            }
            /* eslint-enable */
        }
    }

    return classes.join(' ');
}
