import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from '../../utils/dom-utils';

const Container = (props) => {
    const { children, classes, narrow, spaced } = props;
    return (
        <div
            className={classNames([
                'o-container',
                narrow && 'o-container--narrow',
                spaced && 'o-container--spaced',
                classes && `${classes}`,
            ])}
        >
            {children}
        </div>
    );
};

Container.defaultProps = {
    narrow: false,
    spaced: false,
};

Container.propTypes = {
    classes: PropTypes.string,
    narrow: PropTypes.bool,
    spaced: PropTypes.bool,
    children: PropTypes.any.isRequired,
};

export default Container;
