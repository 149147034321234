export const NAVIGATION = [
    {
        label: 'Home',
        url: '/',
    },
    {
        label: 'Services',
        url: '/services',
    },
    {
        label: 'Contact',
        url: '/contact',
    },
];
export const PHONE = '01772 561222';
export const EMAIL = 'info@barba-hair.co.uk';
export const ADDRESS = '42 Ormskirk Road, Preston, PR1 2QP';
export const BOOKING_URL =
    'https://shops.getsquire.com/book/barba-preston/barber';
export const FACEBOOK_URL = 'https://m.facebook.com/barba.hair.preston';
export const INSTAGRAM_URL = 'https://www.instagram.com/barba.hair/';
