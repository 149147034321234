import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from '../../utils/dom-utils';

const FlexContainer = (props) => {
    const { classes, hasGutters, children, minHeight } = props;

    return (
        <div
            style={minHeight ? { minHeight: `${minHeight}px` } : null}
            className={classNames([
                'o-flex-container',
                !hasGutters && 'o-flex-container--no-gutters',
                classes && `${classes}`,
            ])}
        >
            {children}
        </div>
    );
};

const FlexItem = (props) => {
    const { xsmall, small, medium, large, children, columns, classes } = props;

    return (
        <div
            className={classNames([
                'o-flex-item',
                columns && `o-flex-item--${columns}`,
                xsmall && `o-flex-item--${xsmall}--bp-xsmall`,
                small && `o-flex-item--${small}--bp-small`,
                medium && `o-flex-item--${medium}--bp-medium`,
                large && `o-flex-item--${large}--bp-large`,
                classes && `${classes}`,
            ])}
        >
            {children}
        </div>
    );
};

FlexContainer.defaultProps = {
    hasGutters: true,
};

FlexContainer.propTypes = {
    classes: PropTypes.string,
    hasGutters: PropTypes.bool,
    children: PropTypes.any.isRequired,
    minHeight: PropTypes.number,
};

FlexItem.propTypes = {
    classes: PropTypes.string,
    columns: PropTypes.number,
    xsmall: PropTypes.number,
    small: PropTypes.number,
    medium: PropTypes.number,
    large: PropTypes.number,
    children: PropTypes.any.isRequired,
};

export { FlexContainer, FlexItem };