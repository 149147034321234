import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { FlexContainer, FlexItem } from '../Grid';
import { classNames } from '../../utils/dom-utils';
import { NAVIGATION } from '../../config';

const Header = (props) => {
    const { isDark } = props;

    return (
        <>
            <Helmet>
                <script
                    defer
                    src="https://use.fontawesome.com/releases/v5.15.1/js/all.js"
                    integrity="sha384-9/D4ECZvKMVEJ9Bhr3ZnUAF+Ahlagp1cyPC7h5yDlZdXs4DQ/vRftzfd+2uFUuqS"
                    crossOrigin="anonymous"
                />
            </Helmet>
            <header
                className={classNames(['header', isDark && 'header--is-dark'])}
            >
                <div className="o-container">
                    <FlexContainer classes="u-fbai--center">
                        <FlexItem
                            columns={12}
                            medium={6}
                            classes="u-ta--center u-ta--left--bp-medium"
                        >
                            <Link to="/">
                                {isDark ? (
                                    <img
                                        className="header__logo"
                                        src={`/barba-white-logo.png`}
                                        alt="Barba Logo"
                                    />
                                ) : (
                                    <img
                                        className="header__logo"
                                        src={`/logo-web-black.png`}
                                        alt="Barba Logo"
                                    />
                                )}
                            </Link>
                        </FlexItem>
                        <FlexItem
                            columns={12}
                            medium={6}
                            classes="u-ta--center u-ta--right--bp-medium"
                        >
                            <ul className="header__navigation">
                                {NAVIGATION.map((item) => (
                                    <li
                                        className="header__navigation-item"
                                        key={item.url}
                                    >
                                        <Link to={item.url}>{item.label}</Link>
                                    </li>
                                ))}
                            </ul>
                        </FlexItem>
                    </FlexContainer>
                </div>
            </header>
        </>
    );
};

Header.defaultProps = {
    isDark: false,
};

Header.propTypes = {
    isDark: PropTypes.bool,
};

export default Header;
