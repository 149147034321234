import React from 'react';
import { Link } from 'gatsby';
import Container from '../Container';
import { FlexContainer, FlexItem } from '../Grid';
import {
    NAVIGATION,
    ADDRESS,
    PHONE,
    FACEBOOK_URL,
    INSTAGRAM_URL,
} from '../../config';

const Footer = () => (
    <footer className="footer">
        <Container>
            <FlexContainer>
                <FlexItem columns={12} medium={4}>
                    <h4 className="u-font--heading-3 u-mb--regular">Company</h4>
                    <ul>
                        {NAVIGATION.map((item) => (
                            <li key={item.url}>
                                <Link to={item.url}>{item.label}</Link>
                            </li>
                        ))}
                    </ul>
                </FlexItem>
                <FlexItem columns={12} medium={4}>
                    <h4 className="u-font--heading-3 u-mb--regular">Contact</h4>
                    <span className="u-font--body u-c--white u-d--block">
                        {ADDRESS}
                    </span>
                    {PHONE && (
                        <span className="u-font--body u-c--white u-d--block u-mt--small">
                            <a href={`tel:${PHONE}`}>{PHONE}</a>
                        </span>
                    )}
                </FlexItem>
                <FlexItem columns={12} medium={4}>
                    <h4 className="u-font--heading-3 u-mb--regular">Social</h4>
                    <div className="social-links">
                        <a href={INSTAGRAM_URL} target="_blank">
                            <i className="fab fa-instagram" />
                        </a>
                        <a href={FACEBOOK_URL} target="_blank">
                            <i className="fab fa-facebook" />
                        </a>
                    </div>
                </FlexItem>
            </FlexContainer>
        </Container>
    </footer>
);

export default Footer;
